























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ImageGrid from '@Marketing/components/content/marketing-gallery/ImageGrid.vue'
import TextWithTitle from '@UI/components/text-with-title/TextWithTitle.vue'
import { CmsGallery } from '@Core/@types/skyway'
import Gallery from '@UI/components/gallery/Gallery.vue'

@Component({
  inheritAttrs: false,
  components: {
    ImageGrid,
    TextWithTitle,
    Gallery,
  },
})
export default class MarketingGallery extends Vue {
  @Prop({ type: Object, required: true }) data!: CmsGallery
  @Prop({ type: String, default: 'aspect-4-3' }) imageRatio!: string

  get subComponent(): string | false {
    const map = {
      carousel: 'UISlider',
      grid: 'ImageGrid',
    }
    return map[this.data.extra.type] || false
  }

  get link() {
    return this.data.extra && this.data.extra.link
  }
}
