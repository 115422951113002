

































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ArrowLeftSvg from '~/static/images/svgs/gallery-arrow-left.svg?inline'
import ArrowRightSvg from '~/static/images/svgs/gallery-arrow-right.svg?inline'
import UISlider from '@UI/components/media/UISlider.vue'
import UICmsImageWithCaption from '@UI/components/media/UICmsImageWithCaption.vue'
import UIImage from '@UI/components/media/UIImage.vue'
import { CmsGallery } from '@Core/@types/skyway'
import { SwiperOptions } from 'swiper'

@Component({
  components: {
    UISlider,
    ArrowLeftSvg,
    ArrowRightSvg,
    UIImage,
    UICmsImageWithCaption,
  },
})
export default class Gallery extends Vue {
  @Prop({ type: Object, required: true }) data!: CmsGallery
  @Prop({ type: String, default: 'aspect-4-3' }) imageRatio!: string

  get options(): SwiperOptions {
    return {
      slidesPerView: 1,
      spaceBetween: 8,
      center: true,
      loop: true,
      loopFillGroupWithBlank: false,
      loopAdditionalSlides: 4,
    }
  }
}
