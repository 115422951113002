import { config } from '@Core/config/skyway'

export const responsiveImage = (image, screenSize) => {
  const map = {
    screen375: image.small,
    screen480: image.small,
    screen640: image.medium,
    screen768: image.medium,
    screen1024: image.medium,
    screen1200: image.large,
    screen1440: image.large,
    screen1560: image.large,
  }
  const uri = map[screenSize]
    ? map[screenSize]
    : map.screen1024
    ? map.screen1024
    : image.original

  return uri ? (uri.includes('http') ? uri : `${config.ASSET_PATH}/${uri}`) : ''
}

export const responsiveSvg = (image, screenSize) => {
  const map = {
    screen375: image.portrait,
    screen480: image.portrait,
    screen640: image.portrait,
    screen768: image.portrait,
    screen1024: image.landscape,
    screen1200: image.landscape,
    screen1440: image.landscape,
    screen1560: image.landscape,
  }

  return map[screenSize] ? map[screenSize] : map.screen1024
}

export const responsiveVideo = (video, screenSize) => {
  const map = {
    screen375: video.sizes.small,
    screen480: video.sizes.small,
    screen640: video.sizes.small,
    screen768: video.sizes.small,
    screen1024: video.sizes.large,
    screen1200: video.sizes.large,
    screen1440: video.sizes.large,
    screen1560: video.sizes.large,
  }
  return map[screenSize] ? map[screenSize] : map.screen1024
}
