// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes loading{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}@media only screen and (min-width:768px){.image-grid{display:grid;column-gap:.5rem;row-gap:.5rem;grid-template-columns:1fr 1fr}}@media only screen and (min-width:768px)and (min-width:768px){.image-grid{column-gap:1.5rem;row-gap:1.5rem}}@media only screen and (min-width:768px)and (min-width:1024px){.image-grid{column-gap:2rem;row-gap:2rem}}@media only screen and (min-width:1024px){.image-grid{display:grid;column-gap:.5rem;row-gap:.5rem;grid-template-columns:minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);grid-template-rows:auto}}@media only screen and (min-width:1024px)and (min-width:768px){.image-grid{column-gap:1.5rem;row-gap:1.5rem}}@media only screen and (min-width:1024px)and (min-width:1024px){.image-grid{column-gap:2rem;row-gap:2rem}}.image-grid__item:not(.image-grid__item--new-row){display:flex;flex-direction:column}@media only screen and (min-width:768px){.image-grid__item--new-row{grid-column:span 2}}@media only screen and (min-width:1024px){.image-grid__item--new-row{grid-column:span 3}}.image-grid__item{margin-bottom:3rem}@media only screen and (min-width:768px){.image-grid__item{margin-bottom:0}}.image-grid__item img{aspect-ratio:1/1;height:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#000",
	"blackBg": "#121212",
	"white": "#fff",
	"offWhite": "#f7f6f3",
	"grey": "#b7b7b8",
	"darkGrey": "#474747",
	"mediumGrey": "#7d7d7d",
	"lightGrey": "#e0e0e0",
	"darkGrey60": "rgba(66,66,66,.6)",
	"lightGrey60": "hsla(0,0%,87.8%,.6)",
	"red": "#db0000",
	"violet": "#b8a4ea",
	"lime": "#d4fe01",
	"price1": "#e6ff66",
	"price2": "#b699ff",
	"price3": "#66ff91",
	"price4": "#f68383",
	"price5": "#80a4ff",
	"price6": "#ff99c0",
	"price7": "#00b893",
	"price8": "#ff9166",
	"screen375": "375px",
	"screen480": "480px",
	"screen640": "640px",
	"screen768": "768px",
	"screen1024": "1024px",
	"screen1200": "1200px",
	"screen1440": "1440px",
	"screen1560": "1560px"
};
module.exports = ___CSS_LOADER_EXPORT___;
