
















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { CmsTextBlockWithTitle } from '@Core/@types/skyway'

@Component
export default class TextWithTitle extends Vue {
  @Prop({ type: Object, required: true }) data!: CmsTextBlockWithTitle
}
