





import { Component, Prop, Vue } from 'nuxt-property-decorator'
import Cta from '@UI/components/cta/Cta.vue'
import { CmsLink } from '@Core/@types/skyway'

@Component({
  components: {
    Cta,
  },
})
export default class ImageGridLink extends Vue {
  @Prop({ type: Object, required: true }) data!: CmsLink

  get transformed() {
    return {
      link: this.data,
      extra: {
        type: 'primary',
      },
    }
  }
}
