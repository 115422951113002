



















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import UICmsImageWithCaption from '@UI/components/media/UICmsImageWithCaption.vue'
import ImageGridLink from '@Marketing/components/content/marketing-gallery/ImageGridLink.vue'
import { CmsImage, CmsLink } from '@Core/@types/skyway'

@Component({
  components: {
    UICmsImageWithCaption,
    ImageGridLink,
  },
})
export default class ImageGrid extends Vue {
  @Prop({ type: Array, required: true }) items!: CmsImage[]
  @Prop({ type: Object }) link?: CmsLink

  get hasLink(): boolean {
    return Boolean(this.link && this.link.url)
  }

  get linkOnNewRow(): boolean {
    const perRow = this.$isMobile(this.$mq)
      ? 1
      : this.$isMobileOrTablet(this.$mq)
      ? 2
      : 3

    return this.items.length % perRow === 0
  }
}
