






import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { responsiveImage } from '@UI/helpers/responsiveness'
import { CmsImage } from '@Core/@types/skyway'

@Component({
  methods: {
    responsiveImage,
  },
})
export default class UIImage extends Vue {
  @Prop({ type: Object, required: true }) item!: CmsImage
  @Prop({ type: String, default: 'aspect-4-3' }) imageRatio!: string

  get imageSrc(): string {
    return this.$isMobile(this.$mq)
      ? this.item.sizes.medium_large
      : this.item.sizes.large
  }
}
